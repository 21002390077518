<template>
  <div>
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
</template>

<script>
import network from '@common/network/network';

export default {
  created() {
    if (this.$route.name === 'zupu-viewer') {
      return network.pedigree
        .getClanPedigreeDetails({id: this.$route.params.zupuId})
        .then(zupu => {
          this.$router.replace({
            name: 'source-viewer',
            params: {sourceId: zupu.source_object_id},
            query: this.$route.query,
          });
        })
        .catch(err => {
          this.$router.replace({
            name: 'search-tool-zupu-detail',
            params: {zupuId: this.$route.params.zupuId},
          });
        });
    }
  },
  name: 'RedirectResolver',
};
</script>

<style lang="scss" scoped></style>
